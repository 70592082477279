<template>
  <section v-if="data?.article_article?.length">
    <!--  Header  -->
    <div class="mb-6 flex flex-col justify-between md:flex-row md:items-center">
      <h2
        class="text-heading-4 lg:text-heading-2 mb-4 font-semibold md:mb-0"
        :class="titleClass"
      >
        Новости и Акции
      </h2>
      <div class="flex gap-3">
        <Btn to="/articles/" xl primary class="h-13 px-7 text-base">
          Смотреть все
        </Btn>
        <Btn
          ref="prevButtonRef"
          secondary
          class="news-button-prev"
          :class="[navigationButtonStyleClass]"
        >
          <Icon
            name="expand-left"
            class="m-auto h-6 w-6"
            aria-label="Предыдущий"
          />
        </Btn>
        <Btn
          ref="nextButtonRef"
          secondary
          class="news-button-next"
          :class="[navigationButtonStyleClass]"
        >
          <Icon
            name="expand-right"
            class="m-auto h-6 w-6"
            aria-label="Следующий"
          />
        </Btn>
      </div>
    </div>

    <Swiper
      :navigation="{
        prevEl: prevButtonElement,
        nextEl: nextButtonElement,
      }"
      :slides-per-view="1.1"
      :space-between="28"
      :modules="[Navigation]"
      :breakpoints="{
        768: {
          slidesPerView: 2.5,
        },
        1024: {
          slidesPerView: 3,
        },
      }"
      class="relative"
    >
      <template v-for="a in data?.article_article" :key="a?.id">
        <SwiperSlide v-if="!a.is_archive">
          <NuxtLink :to="{ name: 'articles-slug', params: { slug: a.slug } }">
            <article class="cursor-pointer">
              <picture>
                <source
                  :srcset="joinURL(path, `${a.image_id}.webp`)"
                  type="image/webp"
                />
                <img
                  class="h-[212px] w-full rounded-xl object-cover"
                  :src="joinURL(path, `${a.image_id}.png`)"
                />
              </picture>
              <h4
                class="text-blue-gray-700 mb-[14px] mt-[28px] text-2xl font-semibold"
              >
                {{ a.title }}
              </h4>
              <p class="text-base">
                {{ a.description }}
              </p>
            </article>
          </NuxtLink>
        </SwiperSlide>
      </template>
    </Swiper>
  </section>
</template>

<script setup>
import { joinURL } from 'ufo'

import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'

defineProps({
  titleClass: {
    type: String,
    default: '',
  },
})

const path = joinURL(useRuntimeConfig().public.CDN_URL, '/images/')

const prevButtonRef = ref()
const nextButtonRef = ref()

const navigationButtonStyleClass = ref(
  'bg-white text-primary border-solid border-2 box-border border-primary rounded-[18px] block h-13 w-13 hover:enabled:bg-primary hover:enabled:text-white',
)

const prevButtonElement = computed(
  () => (prevButtonRef.value && prevButtonRef.value.$el) || '.news-button-prev',
)
const nextButtonElement = computed(
  () => (nextButtonRef.value && nextButtonRef.value.$el) || '.news-button-next',
)

const { data } = await useArticlesQuery()
</script>
