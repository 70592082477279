<template>
  <div
    v-if="props.items?.length"
    :class="[`main-banner main-banner-${props.color} relative`]"
  >
    <Swiper
      ref="SwiperCarousel"
      :modules="[Navigation, Pagination, Autoplay]"
      :navigation="{
        prevEl: prevEl,
        nextEl: nextEl,
      }"
      :autoplay="
        props.items?.length > 1 && {
          delay: 3500,
          disableOnInteraction: true,
          pauseOnMouseEnter: false,
        }
      "
      :space-between="10"
      :pagination="{
        clickable: true,
        el: paginationEl,
        ...(props.color === 'isb' && { bulletClass: '!bg-accent-secondary' }),
        bulletActiveClass:
          props.color === 'red'
            ? '!bg-[#E85C54]'
            : props.color === 'violet'
              ? '!bg-violet-900'
              : props.color === 'isb'
                ? '!bg-primary-500'
                : '!bg-primary !w-[32px] sm:!w-[97px]',
        bulletElement: 'div',
      }"
      :auto-height="autoHeight"
      :loop="loop"
      class="relative"
      @swiper="isReady = true"
      @slide-change="onSliceChange"
    >
      <SwiperSlide
        v-for="(item, i) in isReady ? props.items : [props.items[0]]"
        :key="i"
        :data-banner-id="item.id"
        class="translate-z-0 flex flex-col justify-between overflow-hidden rounded-3xl text-white md:block md:h-auto"
        :style="{
          ...(item.color && SITE_ID !== 13 && { background: item.color }),
        }"
        :class="[
          (SITE_ID === 12 || SITE_ID === 13) && '!grid grid-cols-[1fr]',
          SITE_ID === 7 || SITE_ID === 12
            ? 'md:grid-cols-[1fr_1fr]'
            : 'lg:grid-cols-[1fr_460px]',
        ]"
      >
        <template v-if="SITE_ID === 7">
          <div class="relative overflow-hidden bg-[#FBEFE8] md:h-[413px]">
            <div
              class="absolute inset-x-0 top-3 z-30 mx-auto flex h-[266px] w-full max-w-[90%] grow flex-col items-center justify-center bg-cover bg-center bg-no-repeat p-4 text-white sm:h-[386px] md:left-16 md:mx-0 md:max-w-[457px]"
              :style="{ backgroundImage: `url(${HomeBannerBg})` }"
            >
              <div
                v-if="item.tag === 'logo'"
                class="mb-3 flex flex-col items-center space-y-6"
              >
                <slot name="logo" />
              </div>
              <div
                v-if="item.title"
                class="sm:text-heading-1 text-center text-[28px] font-semibold md:px-8"
                :class="item.tag === 'logo' && '!text-xl sm:!text-3xl'"
              >
                <h1 v-if="i === 0" v-html="item.title" />
                <span v-else v-html="item.title" />
              </div>
              <!-- <div v-if="item.title" class="text-xl font-semibold text-center sm:text-3xl" v-html="item.title" /> -->
              <p
                v-if="item.description"
                class="xl:mt-5.5 mt-3.5 text-center text-lg text-white sm:text-2xl lg:mt-6"
                v-html="item.description"
              />
              <Btn
                :href="item.buttonUrl"
                xl
                primary
                class="text-primary mt-6 h-[54px] w-full max-w-[251px] bg-white md:h-[72px] md:max-w-[271px] lg:mt-8"
              >
                {{ item.buttonText || 'Перейти' }}
              </Btn>

              <div v-if="item.isAd" class="mt-4 text-xs font-medium">
                Реклама, АО "Валта Пет Продактс", ИНН 7718118019, erid:
                {{ item.blockAd }}
              </div>
            </div>
            <div
              class="absolute bottom-0 left-[-20%] right-0 z-20 md:left-[30%]"
            >
              <CircleSvg class="w-[200px] object-cover md:w-[300px]" />
            </div>

            <div class="right-0 z-10 h-full lg:absolute">
              <picture>
                <source media="(max-width: 767px)" :srcset="item.mobileImage" />
                <source
                  media="(min-width: 768px)"
                  :srcset="item.desktopImage"
                />
                <img class="object-cover md:h-full" :src="item.desktopImage" />
              </picture>
            </div>
          </div>
        </template>

        <template v-else-if="SITE_ID === 13 || SITE_ID === 12">
          <div
            class="p-[32px_24px] lg:rounded-3xl lg:p-[64px]"
            :style="{
              ...(SITE_ID === 13 && {
                background: item.color,
              }),
            }"
            :class="[
              { 'cursor-pointer': isDesktop && item.buttonUrl && !item.modal },
            ]"
            @click="
              isDesktop && item.buttonUrl && !item.modal
                ? navigateToUrl(item.buttonUrl)
                : undefined
            "
          >
            <Tag
              v-if="item.tag"
              class="mb-5 h-auto w-fit px-6 py-1.5"
              :style="{
                ...(item.tagTextColour && {
                  color: item.tagTextColour,
                }),
                background: item.tagColour,
              }"
            >
              {{ item.tag }}
            </Tag>
            <img
              v-if="logotypeBanner && !hideLogo"
              class="mb-[30px]"
              :src="logotypeBanner"
              alt="logo"
              height="54"
            />
            <h1 class="!p-0 text-[28px] lg:text-[32px]" :class="[classTitle]">
              {{ item.title }}
            </h1>
            <p v-if="item.description" class="text-[18px]" :class="[classText]">
              {{ item.description }}
            </p>
            <Btn
              :to="item.buttonUrl"
              class="mt-[32px] block w-full rounded-2xl p-[24px_34px] text-center font-medium lg:w-fit"
              :class="[classButton]"
            >
              {{ item.buttonText }}
            </Btn>

            <div v-if="item.isAd" class="mt-4 text-xs font-medium">
              Реклама, АО "Валта Пет Продактс", ИНН 7718118019, erid:
              {{ item.blockAd }}
            </div>
          </div>

          <picture
            class="right-0 top-0 mt-6 flex justify-end md:absolute md:left-[460px] md:mt-auto md:h-full lg:left-[605px] xl:left-auto xl:right-0"
          >
            <source
              v-if="item.mobileImageWebp"
              :srcset="item.mobileImageWebp"
              media="(max-width: 639px)"
              type="image/webp"
            />
            <source
              v-if="item.mobileImagePng"
              :srcset="item.mobileImagePng"
              media="(max-width: 639px)"
              type="image/png"
            />
            <source
              v-if="item.desktopImage"
              :srcset="item.desktopImageWebp"
              type="image/webp"
            />
            <img
              :src="item.desktopImagePng"
              :alt="cleanAlt(item.alt) || cleanAlt(item.title)"
              :title="cleanAlt(item.alt) || cleanAlt(item.title)"
              class="w-full max-w-none md:h-full md:w-auto"
            />
          </picture>
          <div v-if="false" class="relative overflow-hidden rounded-3xl">
            <img
              class="static left-0 top-0 hidden h-full w-full object-cover lg:absolute lg:block"
              :src="item.desktopImage"
              alt=""
            />
            <img
              class="static block h-full w-full object-cover lg:hidden"
              :src="item.mobileImagePng"
              alt=""
            />
          </div>
        </template>

        <template v-else>
          <div
            class="relative z-10 flex min-h-[265px] flex-col justify-between p-4 pb-0 sm:p-6 md:h-full md:p-[62px] md:px-11 md:pb-4 lg:justify-center lg:px-16 lg:pt-11"
            :class="[
              { 'cursor-pointer': isDesktop && item.buttonText && !item.modal },
              contentClass,
              !!item.buttonText ? 'lg:min-h-[408px]' : 'lg:min-h-[357px]',
              SITE_ID === 9 && 'md:pb-7',
            ]"
            @click="
              isDesktop && item.buttonUrl && !item.modal
                ? navigateToUrl(item.buttonUrl)
                : undefined
            "
          >
            <div
              class="md:mb-13 pointer-events-auto lg:mb-4"
              :class="SITE_ID === 9 && 'md:mb-4'"
            >
              <div class="mb-[12px] flex items-center">
                <!--              <div-->
                <!--                v-if="SITE_ID === 12"-->
                <!--                class="p-2 font-bold text-white bg-red-900 rounded-lg w-fit"-->
                <!--                :class="{ '!bg-white text-red-900': item.id === 3 }"-->
                <!--              >-->
                <!--                Скидка - 15%-->
                <!--              </div>-->
                <picture
                  v-if="!hideLogo"
                  class="mr-[20px] block w-1/2 sm:w-auto xl:inline-block"
                >
                  <!--                  <source-->
                  <!--                    :srcset="logotypeBannerWebp || BannerLogoWebp"-->
                  <!--                    type="image/webp"-->
                  <!--                  />-->
                  <img
                    :class="[classLogo]"
                    :src="logotypeBanner || BannerLogoPng"
                    :width="logotypeBannerSize.width"
                    :height="logotypeBannerSize.height"
                    alt="logo"
                  />
                </picture>
                <Tag
                  v-if="item.tag"
                  class="h-auto w-fit px-6 py-1.5"
                  :style="{
                    ...(item.tagTextColour && {
                      color: item.tagTextColour,
                    }),
                    background: item.tagColour,
                  }"
                >
                  {{ item.tag }}
                </Tag>

                <!-- DISCOUNT: desktop -->
                <!-- <div
                  v-if="item.id === 0 && $route.name === 'index'"
                  class="hidden md:block bg-[#FF808F] text-white w-fit p-2 rounded-lg font-bold ml-5"
                >
                  Скидка - 20%
                </div> -->
              </div>
              <!-- Сделал из новинки скидку, если что -->
              <!--<div
                v-if="item.new"
                class="text-white bg-red-700 font-medium text-base leading-none inline-flex items-center rounded-lg h-8 px-2 mb-3.5 lg:mb-5 xl:mb-0 xl:ml-6"
              >
                Скидка -20%
              </div>-->
              <!-- titles -->
              <h1
                v-if="item.id === 4"
                :class="[classTitle]"
                v-html="$sanitize(item.title)"
              />
              <h2 v-else :class="[classTitle]" v-html="$sanitize(item.title)" />
              <!-- /tiles -->
              <p
                v-if="item.description !== ''"
                :class="[classText]"
                class="text-3 md:text-heading-5 leading-[19px] md:max-w-[397px] md:leading-[21px]"
                v-html="$sanitize(item.description)"
              />

              <!-- GEMON mobile catalog button -->
              <Btn
                v-if="SITE_ID === 12"
                :to="item.buttonUrl"
                xl
                class="pointer-events-auto mt-3 block h-auto rounded-xl bg-white p-2 text-center text-lg font-medium md:hidden"
              >
                {{ item.buttonText }}
              </Btn>
              <!--              <div-->
              <!--                v-if="item.description !== ''"-->
              <!--                class="inline-block py-3 font-semibold mt-7 rounded-xl text-heading-5 px-7"-->
              <!--                :class="[item.classLabel]"-->
              <!--              >-->
              <!--                {{ item.description }}-->
              <!--              </div>-->
              <!-- DISCOUNT: mobile Раскомментировать 8 ноября -->
              <div
                v-if="item.id === 0 && $route.name === 'index'"
                class="mb-2 mt-3 w-fit rounded-lg bg-[#FF808F] p-2 font-bold text-white sm:hidden"
              >
                Скидка - 20%
              </div>
            </div>
            <!--'text-sapphire-900': props.color === 'blue',-->
            <Btn
              v-if="item.buttonText"
              :to="item.buttonUrl"
              lg
              class="text-3 pointer-events-auto mt-6 flex items-center justify-center rounded-[16px] bg-white text-center font-medium leading-6 text-white md:hidden"
              :class="{
                'text-violet-900': props.color === 'violet',
                'text-sapphire-900': props.color === 'blue',
                'text-[#E85C54]': props.color === 'red',
                'mt-0': SITE_ID === 12 && !item.description,
                [classButton]: true,
              }"
            >
              {{ item.buttonText }}
            </Btn>
            <!--          'text-sapphire-900': props.color === 'blue',-->
            <template v-if="!!item.buttonText">
              <Btn
                v-if="!item.modal"
                :to="item.buttonUrl"
                xl
                class="text-1 pointer-events-auto mt-auto hidden max-w-[307px] rounded-[16px] bg-white p-[19px] text-center font-medium leading-6 md:block"
                :class="{
                  'text-violet-900': props.color === 'violet',
                  'text-[#E85C54]': props.color === 'red',
                  'text-sapphire-900': props.color === 'blue',
                  'mt-0': SITE_ID === 12 && !item.description,
                  [classButton]: true,
                }"
              >
                {{ item.buttonText }}
              </Btn>
              <!--          'text-sapphire-900': props.color === 'blue',-->
              <Btn
                v-else
                xl
                class="text-1 pointer-events-auto mt-auto hidden max-w-[307px] rounded-[16px] bg-white p-[19px] text-center font-medium leading-6 md:block"
                :class="{
                  'text-violet-900': props.color === 'violet',
                  'text-[#E85C54]': props.color === 'red',
                  'text-sapphire-900': props.color === 'blue',
                  [classButton]: true,
                }"
                @click="isActiveModal = `modal-banner-${i}`"
              >
                {{ item.buttonText }}
              </Btn>
            </template>

            <div
              v-if="item.isAd"
              class="mt-4 text-xs font-medium text-[#9199A0]"
            >
              Реклама, АО "Валта Пет Продактс", ИНН 7718118019, erid:
              {{ item.blockAd }}
            </div>
          </div>
          <picture
            class="right-0 top-0 mt-3 block sm:mt-6 md:absolute md:left-[460px] md:mt-auto md:h-full lg:left-[605px] xl:left-auto xl:right-0"
          >
            <source
              v-if="item.mobileImageWebp"
              :srcset="item.mobileImageWebp"
              media="(max-width: 639px)"
              type="image/webp"
            />
            <source
              v-if="item.mobileImage"
              :srcset="item.mobileImagePng"
              media="(max-width: 639px)"
              type="image/png"
            />
            <source
              v-if="item.desktopImageWebp"
              :srcset="item.desktopImageWebp"
              type="image/webp"
            />
            <img
              :src="item.desktopImagePng"
              :alt="cleanAlt(item.alt) || cleanAlt(item.title)"
              :title="cleanAlt(item.alt) || cleanAlt(item.title)"
              class="h-[200px] w-full max-w-none rounded-t-3xl object-cover object-center sm:h-full md:w-auto xl:ml-auto"
              :class="SITE_ID === 9 && 'h-auto'"
            />
          </picture>
        </template>
      </SwiperSlide>

      <template v-if="showNav" #container-end>
        <div
          ref="paginationRef"
          class="main-banner-pagination relative z-10 mt-4 flex justify-center"
        />
      </template>
    </Swiper>
    <!-- LEFT & RIGHT navigation -->
    <template v-if="showNav">
      <Btn
        id="prevBanner"
        ref="prevRef"
        secondary
        class="main-banner-button-prev z-15 absolute left-[25px] top-1/2 z-10 -mt-[15%] hidden h-[62px] w-[62px] -translate-x-1/2 -translate-y-1/2 rounded-[18px] bg-white shadow lg:left-0 lg:-mt-[20px] lg:block"
        aria-label="Предыдущий"
      >
        <Icon
          name="expand-left"
          class="m-auto h-6 w-6"
          :class="{
            '!text-violet-900': props.color === 'violet',
            '!text-sapphire-900': props.color === 'blue',
            '!text-[#E85C54]': props.color === 'red',
          }"
        />
      </Btn>

      <Btn
        id="nextBanner"
        ref="nextRef"
        secondary
        class="main-banner-button-next z-15 absolute right-[25px] top-1/2 z-10 -mt-[15%] hidden h-[62px] w-[62px] -translate-y-1/2 translate-x-1/2 rounded-[18px] bg-white shadow lg:right-0 lg:-mt-[20px] lg:block"
        aria-label="Следующий"
      >
        <Icon
          name="expand-right"
          class="m-auto h-6 w-6"
          :class="{
            '!text-violet-900': props.color === 'violet',
            '!text-sapphire-900': props.color === 'blue',
            '!text-[#E85C54]': props.color === 'red',
          }"
        />
      </Btn>
    </template>

    <ClientOnly>
      <Teleport to="body">
        <template v-for="(item, index) in props.items" :key="index">
          <BannerDialogContainer
            v-if="isActiveModal === `modal-banner-${index}`"
            v-model="isActiveModal"
          >
            <BannerDialog
              v-model="isActiveModal"
              :color="props.color"
              :modal="item.modal"
              :title="item.title"
            />
          </BannerDialogContainer>
        </template>
      </Teleport>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import CircleSvg from '@valta/assets/images/home-circle.svg'
import BannerLogoPng from '@valta/assets/images/banner-logo.png'
import HomeBannerBg from '@valta/assets/images/home-banner-bg.svg?url'
import type { Banner } from '@/openapi_fetch'

const isReady = ref()
const isActiveModal = ref('')

const props = withDefaults(
  defineProps<{
    items: (Banner & { alt: string; modal: { titleBottom: string } })[]
    background: string
    color?: string
    mobileTextBtn: string
    textBtn?: string
    name?: string
    loop: boolean
    hideLogo?: boolean
    hideNav?: boolean
    fraction?: boolean
    contentClass?: boolean
    classTitle: string
    classText: string
    classButton: string
    classLogo?: string
    autoHeight?: boolean
    logotypeBanner: string | any
    logotypeBannerWebp: string | any
    logotypeBannerSize: { width: number | string; height: number | string }
  }>(),
  {
    items: () => [],
    background: 'bg-sapphire-900',
    color: 'blue',
    textBtn: 'Узнать подробнее',
    name: '',
    autoHeight: true,
    classLogo: '',
    classTitle: '',
    classText: '',
    classButton: '',
    logotypeBannerSize: () => ({ width: '', height: '' }),
  },
)

const { SITE_ID } = useRuntimeConfig().public
const { $sanitize } = useNuxtApp()
const { isDesktop } = useDevice()
const { BannersApi } = useOpenApi()

const prevRef = ref()
const nextRef = ref()
const paginationRef = ref()

async function onSliceChange(swiper: any) {
  const id = swiper.slides[swiper.activeIndex].dataset.bannerId
  try {
    await BannersApi.bannersAddViewUpdate({ id })
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.error('[bannersAddViewUpdate]: ', error.message)
  }
}

const prevEl = computed(
  () => (prevRef.value && prevRef.value.$el) || '.main-banner-button-prev',
)
const nextEl = computed(
  () => (nextRef.value && nextRef.value.$el) || '.main-banner-button-next',
)
const paginationEl = computed(
  () => paginationRef.value || '.main-banner-pagination',
)

const showNav = computed(() => {
  const length = props.items?.length ?? 0
  return length > 1 && !props.hideNav
})

function cleanAlt(alt?: string) {
  return alt?.replace(/(<([^>]+)>)/gi, '')
}

function navigateToUrl(url: string) {
  navigateTo(url, { external: url.startsWith('http') })
}
</script>
