<template>
  <div
    class="lg:justify-left justify-left mb-8 flex w-full flex-nowrap items-center pr-8 text-left md:w-1/4 md:flex-wrap md:justify-center md:text-center lg:w-1/4 lg:flex-nowrap lg:text-left"
  >
    <div
      class="text-blue-gray-700 relative mr-4 flex h-[82px] w-[82px] min-w-[82px] items-center justify-center p-6 text-center text-[14px] leading-[18px] md:mb-2 md:mr-0 lg:mr-4 lg:h-[112px] lg:w-[112px] lg:min-w-[112px] lg:text-[18px] lg:leading-[22px]"
    >
      {{ props.text }}

      <Chart1
        v-if="props.chart === '1'"
        class="absolute left-0 top-0 h-[82px] w-[82px] lg:h-[112px] lg:w-[112px]"
      />
      <Chart2
        v-if="props.chart === '2'"
        class="absolute left-0 top-0 h-[82px] w-[82px] lg:h-[112px] lg:w-[112px]"
      />
      <Chart3
        v-if="props.chart === '3'"
        class="absolute left-0 top-0 h-[82px] w-[82px] lg:h-[112px] lg:w-[112px]"
      />
      <Chart4
        v-if="props.chart === '4'"
        class="absolute left-0 top-0 h-[82px] w-[82px] lg:h-[112px] lg:w-[112px]"
      />
    </div>
    <div
      class="text-blue-gray-700 w-[107px] text-[14px] font-medium leading-[18px] lg:w-auto lg:text-[18px] lg:leading-[22px]"
    >
      {{ props.title }}
    </div>
  </div>
</template>

<script setup>
import Chart1 from '@/assets/images/Chart1.svg'
import Chart2 from '@/assets/images/Chart2.svg'
import Chart3 from '@/assets/images/Chart3.svg'
import Chart4 from '@/assets/images/Chart4.svg'

const props = defineProps({
  title: {
    type: String,
    default: '',
  },

  text: {
    type: String,
    default: '',
  },
  chart: {
    type: String,
    default: '',
  },
})
</script>
